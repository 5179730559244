@import "../../_globalColor";

.education-card {
  display: flex;
  flex-direction: row;
}

.education-card:hover + .education-card-border {
  border-color: $educationCardBorder;
  width: 100%;
  -webkit-transition: width 0.6s ease-in-out;
  -moz-transition: width 0.6s ease-in-out;
  -o-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
}

.education-card-border {
  height: 30px;
  width: 80%;
  border-top: 2px solid $lightBorder1;
  margin-bottom: 20px;
  -webkit-transition: width 0.6s ease-in-out;
  -moz-transition: width 0.6s ease-in-out;
  -o-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
}

.education-head {
  display: flex;
  flex-direction: row;
}

.education-text-school {
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 27px;
}

.education-roundedimg {
  object-fit: cover;
  margin-right: 2rem;
  width: 6rem;
  height: 6rem;
  box-shadow: 0 0.5rem 1rem $lightBoxShadowDarker;
  border-radius: 50%;
}

.education-card-right {
  max-width: 70%;
}

.education-text-subHeader {
  color: $textColor;
  font-weight: 700;
  font-size: 19px;
  margin: 0px;
  padding-top: 0.8rem;
  line-height: normal;
}

.education-text-duration {
  color: $titleColor;
  font-size: 19px;
  margin: 0px;
  padding-top: 0.5rem;
}

.education-text-desc {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.education-text-bullets > ul {
  margin-top: 0.5rem;
}

/* Media Query */
@media (max-width: 1380px) {
  .education-card {
    flex-direction: column;
    padding-bottom: 10px;
  }
  .education-card-right {
    max-width: 90%;
  }
  .education-card-border {
    width: 90%;
  }
  .education-text-subHeader {
    padding-top: 0.5rem;
    font-size: 16px;
  }
  .education-text-school {
    font-size: 20px;
    height: 23px;
  }
  .education-text-duration {
    font-size: 16px;
  }
  .education-roundedimg {
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.5rem;
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .education-card {
    text-align: center;
  }
  .education-card-right {
    max-width: 100%;
  }
  .education-card-border {
    width: 100%;
  }
  .education-roundedimg {
    margin-top: 10px;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .education-text-bullets > ul {
    max-width: 80%;
    margin: auto;
  }
}
